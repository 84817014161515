<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <h1 v-if="id != null && id.length > 0">
            Редактирование пользователя
          </h1>
          <h1 v-else>Создание пользователя</h1>

          <v-row>
            <v-select
              label="Роль"
              v-model="user.primaryRole"
              :readonly="id != null && id.length > 0"
              :items="roleOptions"
              item-text="text"
              item-value="type"
              style="width: 40%"
              @change="onSelectChange($event)"
            ></v-select>
          </v-row>
          <v-row style="padding-bottom: 20px">
            <span v-if="user.primaryRole === 'ROLE_CZN_MANAGER' || user.primaryRole === 'ROLE_CZN_TERRITORIAL'"
              >Сотрудники ЦЗН могут заполнять информацию по показателям
              оперативного мониторинга, мониторинга информационных мероприятий,
              вести учет ситуаций в рамках ЖС/БС, просматривать паспорта своих регионов,
              просматривать и обрабатывать анкеты соискателей, указавших соответсвующий ЦЗН.</span
            >
            <span v-if="user.primaryRole === 'ROLE_ADMIN'"
              >Администраторам доступен просмотр любой информации в системе,
              настройка подсистем, экспорт данных, управление пользователями,
              массовая рассылка уведомлений. Не могут редактировать информацию,
              указанную центрами занятости населения.</span
            >
            <span v-if="user.primaryRole === 'ROLE_VNII'"
              >Сотрудники ВНИИ Труда могут просматривать любую информацию в
              системе, экспортировать данные, направлять массовые уведомления,
              направлять уведомления пользователям. Не могут редактировать
              информацию, указанную центрами занятости населения.</span
            >
            <span v-if="user.primaryRole === 'ROLE_REGIONAL_AGENCY'"
              >Сотрудники региональных органов занятости могут заполнять информацию
              по показателям оперативного мониторинга любого ЦЗН на территории своих регионов,
              мониторинга информационных мероприятий любого ЦЗН на территории своих регионов,
              вести учет ситуаций в рамках ЖС/БС любого ЦЗН на территории своих регионов,
              просматривать паспорта своих регионов,
              просматривать и обрабатывать анкеты соискателей,
              указавших любой ЦЗН на территории соответствующего региона.</span
            >
          </v-row>
          <v-row>
            <v-text-field
              label="Имя пользователя (username)"
              :readonly="id != null"
              v-model="user.username"
            ></v-text-field>
          </v-row>

          <v-row>
            <v-checkbox
              label="Активная учетная запись"
              v-model="user.enabled"
            ></v-checkbox>
          </v-row>

          <v-row>
            <v-text-field label="Полное имя" v-model="user.name"></v-text-field>
          </v-row>

<!--          <v-row v-if="user.primaryRole === 'ROLE_CZN_MANAGER' || user.primaryRole === 'ROLE_CZN_TERRITORIAL'">-->
<!--            <v-radio-group-->
<!--                v-model="searchType"-->
<!--                label="Режим поиска ЦЗН">-->
<!--              <v-radio value="department" label="По внутреннему реестру" @change="user.department = null; isManager = null"></v-radio>-->
<!--              <v-radio value="egrul" label="По ЕГРЮЛ" @change="user.department = null; isManager = null"></v-radio>-->
<!--            </v-radio-group>-->
<!--          </v-row>-->

          <v-row v-if="user.primaryRole === 'ROLE_CZN_MANAGER' || user.primaryRole === 'ROLE_CZN_TERRITORIAL'">
            <v-autocomplete
                v-if="searchType === 'department' && id == null"
                :items="departments"
                v-model="user.department"
                item-text="name"
                return-object
                label="Название ЦЗН"
            >
            </v-autocomplete>
            <v-autocomplete
                v-else-if="searchType === 'department' && id != null"
                :items="departments"
                v-model="user.department"
                item-text="name"
                return-object
                label="Название ЦЗН"
            >
            </v-autocomplete>
            <EgrulInput
                v-if="searchType === 'egrul'"
                @changeNameOfUser="changeNameOfUser"
                :init-value="user.department"
            ></EgrulInput>
          </v-row>

          <v-row v-if="user.primaryRole === 'ROLE_CZN_MANAGER' || user.primaryRole === 'ROLE_CZN_TERRITORIAL'">
            <v-checkbox
                v-if="searchType === 'egrul'"
                label="Управляющий ЦЗН"
                v-model="isManager"
            ></v-checkbox>
          </v-row>

          <v-row>
            <v-checkbox
                v-if="id == null"
                label="Сгенерировать пароль"
                v-model="needGeneratedPassword"
                @change="user.password = null"
            ></v-checkbox>
          </v-row>

          <v-row>
            <v-text-field v-if="needGeneratedPassword && id == null" label="Пароль" disabled></v-text-field>
            <v-text-field v-else-if="id == null" label="Пароль" v-model="user.password"></v-text-field>
          </v-row>

          <v-row>
            <v-text-field label="Email" v-model="user.email" @input="needsEmailExistCheck=true"></v-text-field>
          </v-row>

          <v-row>
            <v-checkbox
              label="Не направлять электронные письма"
              v-model="user.stopEmails"
            ></v-checkbox>
          </v-row>

          <v-row v-if="user.primaryRole === 'ROLE_REGIONAL_AGENCY'
          || user.primaryRole === 'ROLE_REGIONAL_ADMINISTRATOR'">
            <v-select
                multiple
                label="Второстепенные роли"
                :items="secondaryRoleOptions"
                item-text="description"
                item-value="role"
                v-model="user.secondaryRoles"
                style="width: 40%"
            ></v-select>
          </v-row>

          <v-row v-if="user.primaryRole === 'ROLE_CZN_MANAGER'
          || user.primaryRole === 'ROLE_CZN_TERRITORIAL'
          || user.primaryRole === 'ROLE_REGIONAL_AGENCY'
          || user.primaryRole === 'ROLE_REGIONAL_ADMINISTRATOR'">
            <v-autocomplete
              label="Регион"
              v-model="user.region"
              :items="regionsList"
              item-text="name"
              return-object
              style="width: 40%"
              @change="onSelectChange($event)"
              :readonly="$user.role === 'ROLE_REGIONAL_ADMINISTRATOR'"
            ></v-autocomplete>
          </v-row>

          <v-row v-if="id != null && id.length > 0">
            <v-text-field label="Новый пароль" v-model="user.password"></v-text-field>
          </v-row>

          <div class="save-buttons mt-24">
            <v-btn
              color="primary"
              outlined
              @click="resetPassword"
              >Сбросить пароль</v-btn
            >
            <v-btn
              color="primary"
              outlined
              @click="$router.push('/config/users')"
              >Отмена</v-btn
            >
            <v-btn
              :disabled="submitButtonDisabled"
              color="primary"
              @click="submit"
              >Сохранить</v-btn
            >
          </div>

          <div style="float: right" v-if="$user.role !== 'ROLE_REGIONAL_ADMINISTRATOR'">
            <a
              href="#"
              @click.prevent="deleteUser"
              v-if="
                id != null &&
                id.length > 0
              "
              >Удалить пользователя</a
            >
          </div>
        </div>

        <div
          v-if="errorText != null"
          class="error"
          style="position: fixed; bottom: 0"
        >
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height: 1000px"></div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style lang="scss" scoped>
</style>

<script>
import EgrulInput from "../elements/EgrulInput";
import api from "@/modules/api";
export default {
  props: {},
  components: { EgrulInput },
  data() {
    return {
      apiLoaded: false,
      regionsList: [],
      id: null, //id from url param
      type: null, //type from url param
      submitButtonDisabled: false,
      needsEmailExistCheck: true,
      user: {
        primaryRole: 'ROLE_CZN_TERRITORIAL',
        username: null,
        name: null,
        email: null,
        stopEmails: false,
        region: null,
        enabled: true,
        department: null,
        secondaryRoles: [],
      },
      roleOptions: [
        {text: "Территориальный ЦЗН", type: "ROLE_CZN_TERRITORIAL"},
        {text: "Управляющий ЦЗН", type: "ROLE_CZN_MANAGER"},
        {text: "Администратор", type: "ROLE_ADMIN"},
        {text: "Сотрудник ВНИИ Труда", type: "ROLE_VNII"},
        {text: "Региональный орган занятости", type: "ROLE_REGIONAL_AGENCY"},
        {text: "Региональный администратор", type: "ROLE_REGIONAL_ADMINISTRATOR"},
        {text: "Минтруд", type: "ROLE_MINTRUD"}
      ],
      errorText: null,
      secondaryRoleOptions: [],
      searchType: "department",
      departments: [],
      isManager: null,
      needGeneratedPassword: false,
    };
  },
  methods: {
    async loadUserInfo() {
      let req = await this.$getApi("/getUserInfo", {
        type: this.type,
        id: this.id,
      });
      if (req.ok) {
        this.user = req.payload;
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    async loadRegionsList() {
      let req = await this.$getApi("/getRegionsList");
      if (req.ok) {
        this.regionsList = req.payload;
      } else {
        this.errorText =
          "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    onSelectChange() {
      //alert(this.user.role !== 'ROLE_CZN')
      this.$forceUpdate();
    },

    changeNameOfUser(val) {
      this.user.department = val;
    },

    validate() {
      console.log(this.user.department)
      if (this.user.username == null || this.user.username.length === 0)
        return "Укажите имя пользователя (username)";
      if (this.user.name == null || this.user.name.length === 0)
        return "Укажите полное имя пользователя";

      if (this.user.primaryRole === 'ROLE_CZN_MANAGER' || this.user.primaryRole === 'ROLE_CZN_TERRITORIAL'){
        if(this.user.department == null || this.user.department.length === 0) {
          return "Выберите организацию (ЦЗН)";
        }
        if(this.user.department.type == null) {
          return "У выбранной организации не указан тип (территориальный или управляющий ЦЗН / ОИВ). Перед привязыванием к нему пользователей, пожалуйста, укажите данную информацию в справочнике ЦЗН и ОИВ";
        }
        if (this.user.primaryRole === 'ROLE_CZN_MANAGER' && this.user.department.type !== "manager_czn") {
          return "Роль пользователя (Управляющий ЦЗН) не соответствует типу выбранного ЦЗН"
        }
        if (this.user.primaryRole === 'ROLE_CZN_TERRITORIAL' && this.user.department.type !== "territorial_czn") {
          return "Роль пользователя (Территориальный ЦЗН) не соответствует типу выбранного ЦЗН"
        }
      }

      if (this.user.email == null || this.user.email.length === 0)
        return "Укажите адрес электронной почты";
      if (!/.+@.+\..+/.test(this.user.email)) {
        return "Введите корректный адрес электронной почты";
      }
      if ((this.user.primaryRole === 'ROLE_CZN_MANAGER'
              || this.user.primaryRole === 'ROLE_CZN_TERRITORIAL'
              || this.user.primaryRole === 'ROLE_REGIONAL_AGENCY'
              || this.user.primaryRole === 'ROLE_REGIONAL_ADMINISTRATOR')
          && (this.user.region == null || this.user.region.length === 0)) {
        return "Выберите регион"
      }
      return true;
    },

    async checkUsernameExists() {
      let req = await this.$getApi("/userNameExists", {
        username: this.user.username,
      });
      if (req.ok) {
        if (req.payload === true) return true;
        return false;
      }
    },

    async checkEmailExists() {
      let req = await this.$getApi("/checkEmailExists", {
        email: this.user.email,
      });
      if (req.ok) {
        if (req.payload === true) return true;
        return false;
      }
      return false;
    },

    async resetPassword(){
      let req = await this.$postApi("/resetUserPassword", this.user);
      if (req.ok) {
        alert('Пароль сброшен. На почту пользователя направлен новый пароль.')
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    async submit() {
      this.submitButtonDisabled = true;
      let t = this.validate();
      if (t !== true) {
        this.errorText = t;
        this.submitButtonDisabled = false;
        return;
      }
      this.errorText = null;

      let userExists = await this.checkUsernameExists();
      if(this.user.id == null && userExists === true){
        alert("Пользователь с таким именем уже существует");
        this.submitButtonDisabled = false;
        return;
      }

      if (this.needsEmailExistCheck) {
        let emailExists = await this.checkEmailExists();
        if (
          emailExists &&
          !confirm(
            "Пользователь с таким адресом электронной почты существует. Вы действительно хотите продолжить?"
          )
        ){
          this.submitButtonDisabled = false;
          return;
        }

      }



      if (this.searchType === 'egrul') {
        if (this.isManager) {
          this.user.department.type = 'manager_czn';
        } else {
          this.user.department.type = 'territorial_czn';
        }
      }

      if (this.user.allRoles) {
        delete this.user['allRoles'];
      }

      if(!(this.user.primaryRole === 'ROLE_CZN_MANAGER' || this.user.primaryRole === 'ROLE_CZN_TERRITORIAL')){
        this.user.department = null;
      }

      if(!(this.user.primaryRole === 'ROLE_REGIONAL_AGENCY' || this.user.primaryRole === 'ROLE_REGIONAL_ADMINISTRATOR')){
        this.user.secondaryRoles = null
      }

      // console.log(this.user);
      // console.log(this.url);
      let req = await this.$postApi("/saveUser", this.user);
      if (req.ok) {
        this.$router.push("/config/users");
      } else {
        this.errorText =
          "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
      this.submitButtonDisabled = false;
    },

    async deleteUser() {
      if (!confirm("Вы действительно хотите удалить пользователя?")) return;

      let req = await api.delete("/deleteUser", {
        id: this.user.id,
      });
      if (req.ok) {
        this.$router.push("/config/users");
      } else {
        this.errorText =
          "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    async loadDepartments() {
      let req = await this.$getApi("/department/getList");
      if (req.ok) {
        this.departments = req.payload;
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    async loadSecondaryRoleOptions() {
      let req = await this.$getApi("/getSecondaryRolesList");
      if (req.ok) {
        this.secondaryRoleOptions = req.payload;
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    }
  },
  async beforeMount() {
    let p1 = this.loadRegionsList();
    let p2 = this.loadDepartments();
    let p3 = this.loadSecondaryRoleOptions();
    this.id = this.$route.params.id;
    if (this.id != null && this.id !== "") {
      this.needsEmailExistCheck = false;
      await this.loadUserInfo();
    }
    await p1;
    await p2;
    await p3;
    // console.log(this.user);

    if(this.$user.role === 'ROLE_REGIONAL_ADMINISTRATOR'){
      this.roleOptions = [
        {text: "Территориальный ЦЗН", type: "ROLE_CZN_TERRITORIAL"},
        {text: "Управляющий ЦЗН", type: "ROLE_CZN_MANAGER"},
        {text: "Региональный администратор", type: "ROLE_REGIONAL_ADMINISTRATOR"},
        {text: "Региональный орган занятости", type: "ROLE_REGIONAL_AGENCY"}
      ]
      this.user.region = this.$user.region
      this.departments = this.departments.filter(e=>e.region.code === this.$user.region.code)
    }

    this.apiLoaded = true;

    // let vniiUser = {
    //   name: 'Администратор системы',
    //   username: 'admin',
    //   email: 'shevernev@yandex.ru',
    //   stopEmails: false,
    //   role: 'ROLE_ADMIN'
    // }
    //let req = await this.$postApi('/saveVniiUser', vniiUser)
    //console.log(req)
  },
};
</script>
